import { Link } from 'react-router-dom';
import Header from '../components/Header';
import LayoutContainer from '../components/LayoutContainer';
import Main from '../components/Main';
import { UNIVERSITY } from '@/config/constants';
import React, { useEffect, useState } from 'react';

export default function Home() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [university, setUniversity] = useState('');

  useEffect(() => {
    // Retrieve query parameters from URL
    const queryParams = new URLSearchParams(window.location.search);
    const nameParam = queryParams.get('n');
    const universityParam = queryParams.get('u');
    const phoneParam = queryParams.get('p');

    // Update local storage
    if (nameParam) localStorage.setItem('leadName', nameParam);
    if (universityParam) localStorage.setItem('university', universityParam);
    if (phoneParam) localStorage.setItem('phone', phoneParam);

    // Fetch values from local storage
    const storedName = localStorage.getItem('leadName');
    const storedUniversity = localStorage.getItem('university');
    const storedPhone = localStorage.getItem('phone');

    // Update state variables
    setName(storedName || '');
    setUniversity(storedUniversity || '');
    setPhone(storedPhone || '');
  }, []);

  const getUniversityName = (university) => {

    const universityNames = {
      [UNIVERSITY.JAIN]: 'JAIN ONLINE',
      [UNIVERSITY.VIGNAN]: 'VIGNAN ONLINE',
      [UNIVERSITY.YENEPOYA]: 'YENEPOYA ONLINE',
      [UNIVERSITY.AMITY]: 'AMITY UNIVERSITY',

      [UNIVERSITY.CHANDIGRAHUNIVIDERSITY]: 'CHANDIGARH UNIVERSITY',
      [UNIVERSITY.DPU]: 'DR. D. Y. PATIL VIDYAPEETH',
      [UNIVERSITY.ONLINEMANIPAL]: 'ONLINE MANIPAL',
      [UNIVERSITY.SMU]: 'SIKKIM MANIPAL UNIVERSITY',

      [UNIVERSITY.AMR]: "AMRITA ONLINE",
      [UNIVERSITY.LO]: 'LEARN ONLINE',
      [UNIVERSITY.SO]: 'SHARDA UNIVERSITY',
      [UNIVERSITY.IIIT]: 'INDIAN INSTITUTE OF INFORMATION TECHNOLOGY',
  
    };
    return universityNames[university] || '';
  };

  const renderMain = () => {
    return (
      <>
        <Header university={university} />
        <div
          className="min-h-screen w-full bg-cover bg-center bg-no-repeat relative"
          style={{
            backgroundImage: 'url("/persona_bg.png")',
          }}
        >
          <div className="absolute inset-0 "></div>
          <div className="relative w-full min-h-screen flex items-center justify-center">
            <div className="flex flex-col md:flex-row max-w-7xl w-full gap-6 p-4 md:p-6">
              {/* Left Section */}
              <div className="flex-1 flex flex-col justify-center space-y-6 items-center md:items-start text-center md:text-left animate-slide-up">
                <h1 className="text-4xl md:text-6xl font-bold text-gray-800 animate-fade-in">
                  Welcome to
                </h1>
                <h2 className="text-2xl md:text-4xl font-bold text-gray-700 animate-slide-up">
                  {`${getUniversityName(university)}, ${name?.toUpperCase()}`}
                </h2>
                <p className="text-base md:text-lg text-gray-600 animate-fade-in">
                  Let’s get to know you better! Share a bit about yourself so we
                  can guide you to the perfect learning experience.
                </p>
                <div className="mt-4 flex flex-col md:flex-row md:space-x-2 items-center animate-slide-up">
                  <span className="text-black font-medium border border-yellow-400 py-1 px-4 rounded-full mb-2 md:mb-0">
                    ✓ Personalized Learning
                  </span>
                  <span className="text-black font-medium border border-yellow-400 py-1 px-4 rounded-full">
                    ✓ Expert Guidance
                  </span>
                </div>

                <blockquote className="text-gray-500 italic text-sm border-l-4 border-yellow-400 pl-4 animate-fade-in">
                  "Education is the passport to the future, for tomorrow belongs
                  to those who prepare for it today."
                </blockquote>
                <div className="flex justify-center md:justify-start">
                  <Link to="/persona">
                    <button className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-3 px-6 rounded shadow-md transition duration-300 animate-bounce-on-hover">
                      Discover Your Path
                    </button>
                  </Link>
                </div>
              </div>
              {/* Right Section */}
              <div className="hidden md:flex flex-1 items-center justify-center">
                <div
                  className="w-full h-[300px] md:h-[700px] bg-cover bg-center rounded-lg animate-image-hover"
                  style={{
                    backgroundImage: 'url("/pr.webp")',
                  }}
                  aria-label="Hero Image"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderDefaultHome = () => {
    return (
      <>
        <Header />
        <div className="w-full h-screen flex items-center justify-center bg-gray-800 text-white text-3xl">
          Parameters Missing - Lead Name and Lead ID
        </div>
      </>
    );
  };

  return (
    <LayoutContainer>
      <Main className="w-full h-full">
        {name && phone && university ? renderMain() : renderDefaultHome()}
      </Main>
    </LayoutContainer>
  );
}
