import clsx from 'clsx';
import { Row, Col } from 'react-grid-system';

const Header = ({ university }) => {
  // Map universities to their respective logo paths
  const logoMap = {
    jo: '/logo/jo.png',
    am: '/logo/am.png',
    cu: '/logo/cu.png',
    dpu: '/logo/dpu.png',
    om: '/logo/om.png',
    vo: '/logo/vo.webp',
    yo: '/logo/yo.webp',
    smu: '/logo/smu_logo.png',

    amr: '/logo/amr.webp',
    lo: '/logo/lo.webp',
    so: '/logo/so.webp',
    iiit: '/logo/iiit.svg',
  };

  return (
    <Row
      className="w-100"
      style={{
        backgroundColor: '#ffffff',
        height: '90px',
        position: 'absolute',
        zIndex: 10,
        margin: '0',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
      }}
    >
      <Col className={clsx('flex', 'align-center')}>
        <div>
          {logoMap[university] && (
            <img
              src={logoMap[university]}
              alt={`${university} logo`}
              style={{ width: 'auto', height: '60px' }}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Header;
