export const BREAKPOINTS = {
  xs: 550,
  sm: 760,
  md: 1020,
  lg: 1270,
  xl: 1420,
  xxl: 1600,
};

export const UNIVERSITY = {
  JAIN: 'jo',
  VIGNAN: 'vo',
  YENEPOYA: 'yo',
  AMITY: 'am',
  CHANDIGRAHUNIVIDERSITY: 'cu',
  DPU: 'dpu',
  ONLINEMANIPAL: 'om',
  SMU: 'smu',
  AMR: 'amr',
  LO: 'lo',
  SO: 'so',
  IIIT: 'iiit'
};

export const TOKEN_ENDPOINT_URL = import.meta.env.VITE_APP_TOKEN_ENDPOINT_URL;

export const TOKEN_ENDPOINT_XSRF_TOKEN = import.meta.env
  .VITE_APP_TOKEN_ENDPOINT_XSRF_TOKEN;

export const TOKEN_ENDPOINT_AUTHORIZATION = import.meta.env
  .VITE_APP_TOKEN_ENDPOINT_AUTHORIZATION;

export const LEAD_ENDPOINT_URL = import.meta.env.VITE_APP_LEAD_ENDPOINT_URL;
